const theme = {
  colors: {
    text: "#1D1D1B",
    background: "#ffffff",
    lightBackground: "#fafafa",
    primary: "#F60000",
    primaryLight: "rgba(246, 0, 0,.5)",
    secondary: "#000000",
    light: "#ffffff",
    lighten: "rgba(255,255,255,.8)",
    dark: "#1D1D1B",
    gray: "#756F6E",
    lightGrey: "rgba(189,189,189,.4)",
    backgroundColor: "#F5F5F5"
  },
  fonts: {
    body: '"proxima-nova", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      '"proxima-nova", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  space: [0, 4, 8, 16, 32, 40, 48, 64, 96, 128, 192, 256, 320, 384, 448, 512],
  fontSizes: [12, 14, 16, 20, 24, 32, 40, 48, 64, 96],
  fontWeights: {
    body: 400,
    heading: 700,
  },
  lineHeights: {
    body: 1.25,
    heading: 1,
  },
  sizes: {
    container: 1280,
  },
  radii: {
    none: "0",
    xs: ".25rem",
    sm: ".5rem",
    md: "1rem",
    lg: "2rem",
    full: "9999px",
  },
  shadows: {
    none: "none",
    default:
      "0 20px 40px -10px rgba(50,50,93,0.15),0 10px 30px -20px rgba(0,0,0,0.15)",
  },
  text: {
    default: {
      lineHeight: "body",
    },
    heading: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
    },
    h1: {
      fontSize: [6, 9],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
    },
    h2: {
      fontSize: [6,6,6, 8],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
      mt: 4,
    },
    h3: {
      fontSize: [5, 7],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
      mt: 4,
    },
    h4: {
      fontSize: [4,6],
      fontWeight: "bold",
      color: "primary",
      mb: 2,
      mt: 4,
    },
    h5: {
      fontSize: [4, 5],
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    h6: {
      fontSize: [3, 4],
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    caption: {
      fontSize: 0,
      color: "gray",
      fontWeight: "bold",
    },
    article: {
      fontSize: 3,
      lineHeight: 1.5,
    },
    sectionTitle: {
      "h3,h2": {
        margin: "inherit",
        fontFamily: "heading",
        fontSize: [6],
        lineHeight: "1.2",
        fontWeight: 300,
        em: {
          fontStyle: "normal",
          color: "dark",
        },
        position: "relative",
        pt: [3],
        pb: [4],
        "&::after": {
          content: "''",
          backgroundColor: "primary",
          position: "absolute",
          top: [0],
          left: [0],
          height: "5px",
          width: "25px",
        },
      },
      h3: {
        margin: "inherit",
        fontFamily: "heading",
        fontSize: [4, 5],
        lineHeight: "1.2",
        fontWeight: 300,
        em: {
          fontStyle: "normal",
          color: "dark",
        },
        position: "relative",
        pt: [3],
        pb: [4],
        "&::after": {
          content: "''",
          backgroundColor: "primary",
          position: "absolute",
          top: [0],
          left: [0],
          height: "5px",
          width: "25px",
        },
      },
      "> div": {
        borderBottom: "1px solid",
        borderColor: "dark",
      },
      "> div.light": {
        borderBottom: "1px solid",
        borderColor: "light",
      },
      span: {
        display: "block",
        color:"primary",
        textTransform:"lowercase"

      },
      p: {
        margin: "inherit",
        fontSize: [1],
        letterSpacing: "2px",
        lineHeight: "1.4",
        em: {
          fontStyle: "normal",
          color: "primary",
        },
      },
      ul: {
        p: 0,
        listStyleType: "none",
        margin: "inherit",
        fontSize: [1],
        letterSpacing: "2px",
        lineHeight: "1.7",
        em: {
          fontStyle: "normal",
          color: "dark",
        },
        ml: [1],
        li: {
          color: "dark",
          position: "relative",
          "span:before": {
            content: "'//'",
            color: "dark",
            ml: [1],
            position: "absolute",
            left: [-3, -4, -4, -4],
          },
        },
      },
    },
  },
  layout: {
    container: {
      padding: [3, 4],
    },
    sm: {
      maxWidth: "720px",
      padding: [3, 4],
    },
    md: {
      maxWidth: "1020px",
      padding: [3, 4],
    },
    fw: {
      maxWidth: "100%",
      padding: [3, 4],
    },
    header: {
      maxWidth: "100%",
    },
  },
  buttons: {
    fullEmpty:{
      p: [3],
      cursor:"pointer",
      color:"light",
      border:"1px solid",
      borderColor:"light",
      borderRadius: "none",
      "&:hover":{
        color:"primary",
        border:"1px solid",
        borderColor:"light",
        backgroundColor: "light"
      }
    },
    primary: {
      cursor: "pointer",
      color: "light",
      bg: "primary",
      "&:hover": {
        bg: "dark",
      },
    },
    secondary: {
      color: "background",
      bg: "secondary",
    },
  },
  inputs: {
    light: {
      p: [3],
      border: "1px solid",
      borderRadius: "0px!important",
      cursor: "pointer",
      color: "light",
      borderColor: "light",
      "&:focus": {
        outline: "none",
        backgroundColor: "light",
        color: "dark",
        border: "1px solid light",
      },
      "::placeholder": {
        /* Chrome, Firefox, Opera, Safari 10.1+ */ color: "light",
        opacity: 1 /* Firefox */,
      },
    },
  },
  links: {
    nav: {
      paddingX: 3,
      paddingY: 3,
      backgroundColor: "primary",
      "&.active": {
        color: "primary",
      },
    },
    tab: {
      textDecoration: "none",
      mr: 3,
      color: "text",
      "&.active": {
        color: "primary",
        fontWeight: "bold",
      },
    },
  },
  styles: {
    root: {
      html :{
        scrollBehavior: "smooth"
      },
      "*": {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        "text-rendering": "optimizeLegibility",

        "-webkit-transition": "background-color .2s linear",
        "-ms-transition": "background-color .2s linear",
        transition: "background-color .2s linear",
      },
      fontFamily: "body",
      fontWeight: "body",
      "a,button": {
        
        "-webkit-transition": "background-color .2s linear",
        "-ms-transition": "background-color .2s linear",
        transition: "background-color .2s linear",
        textDecoration: "none",
        color:"primary",
        textDecoration: "underline",
        "&.active" :{
          color: "primary",
          //textDecoration: "underline",
        }
        // color: "primary",
        // "&:hover": {
        //   textDecoration: "none",
        // },
      },
      "--swiper-theme-color": "#EA0029",
      ".swiper-container": { pb: 5 },
      ".oneSide": {
        p: [3, 4],
        pr: [3, 0, 0, 0],
        mr: ["auto", 0, 0, 0],
        ml: ["auto", "calc(50vw - 485px)", "calc(50vw - 640px)"],

        
      },
      "#gatsby-focus-wrapper": {
        position: "relative",
      },
      ".swiper-slide" :{
        height: "auto"
      }
    },
  },
}

export default theme
